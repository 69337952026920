
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {AdvertisementSize} from "@/enum/AdvertisementSize.enum";
import AdvertisementRepository from "@/api/repositories/AdvertisementRepository";
import Advertisement from "@/models/Advertisement.model";

interface AdvertisementSizeInterface {
  width: any,
  height: any
}

@Component
export default class AdvertisementComponent extends Vue {
  @Prop({required: true})
  private size!: AdvertisementSize;

  private sizes = new Map<AdvertisementSize, AdvertisementSizeInterface>([
      [AdvertisementSize.MEDIUM_RECTANGLE, {width: 300, height: 250}],
      [AdvertisementSize.STANDARD_LEADERBOARD, {width: 728, height: 90}],
      [AdvertisementSize.LARGE_LEADERBOARD, {width: 970, height: 90}],
      [AdvertisementSize.BILLBOARD, {width: 970, height: 250}],
  ]);

  private get width() {
    const width = this.sizes.get(this.size)?.width;
    return width ?? '100%';
  }

  private get height() {
    const height = this.sizes.get(this.size)?.height;
    return height ?? '100%';
  }

  /**
   * the advertisement that should be shown inside the component
   */
  private advertisement: Advertisement = new Advertisement();

  /**
   * flag that indicates if the view is loading
   * @private
   */
  private isLoading = false;

  private async created() {
    try {
      this.isLoading = true;
      const {data} = await AdvertisementRepository.getAdvertisements();
      console.log(data);
      this.advertisement = Advertisement.parseFromObject({...data[this.size.toString()]});
    } finally {
      this.isLoading = false;
    }
  }
}
