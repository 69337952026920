import { render, staticRenderFns } from "./Advertisement.component.vue?vue&type=template&id=128a6095&scoped=true&"
import script from "./Advertisement.component.vue?vue&type=script&lang=ts&"
export * from "./Advertisement.component.vue?vue&type=script&lang=ts&"
import style0 from "./Advertisement.component.vue?vue&type=style&index=0&id=128a6095&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128a6095",
  null
  
)

export default component.exports